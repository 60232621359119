* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'SF Pro Display';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F9F9F9 !important;
}
@font-face { 
  font-family: 'SF Pro Display';
  src: url("../assets/fonts/SFUIDisplay-Black.otf");
  src: url("../assets/fonts/SFUIDisplay-Heavy.otf");
  src: url("../assets/fonts/SFUIDisplay-Thin.otf");
  src: url("../assets/fonts/SFUIDisplay-Ultralight.otf")
}
@font-face {
  font-family: 'SF Pro Display';
  src: url("../assets/fonts/SFUIDisplay-Bold.ttf");
    font-weight: 700;
    font-style: bold;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url("../assets/fonts/SFUIDisplay-Semibold.otf");
    font-weight: 600;
    font-style:Semibold;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url("../assets/fonts/SFUIDisplay-Regular.otf");
    font-weight: 400;
    font-style: Regular;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url("../assets/fonts/SFUIDisplay-Medium.otf");
    font-weight: 500;
    font-style: medium;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url("../assets/fonts/SFUIDisplay-Light.ttf");
    font-weight: 300;
    font-style: light;
}
h1,h2,h3,h4,h5,h6,section,div,ul,li,a,span,td,tr,tbody,thead{
  font-family: 'SF Pro Display';
}
::selection {
  background: #ffd072;
}
*::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
}
*::-webkit-scrollbar-track {
    background: #f2f2f2; 

}
*::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}
/* input[type=date]::-webkit-datetime-edit-text {
  -webkit-appearance: none;
  display: none;
}
input[type=date]::-webkit-datetime-edit-month-field{
  -webkit-appearance: none;
  display: none;
}
input[type=date]::-webkit-datetime-edit-day-field {
  -webkit-appearance: none;
  display: none;
}
input[type=date]::-webkit-datetime-edit-year-field {
  -webkit-appearance: none;
  display: none;
} */