.modal-title {
    font-size: 18px;
}

.modal-body{
    padding-bottom: 0.5rem;
}

.modal-footer {
    border-top : none;
    justify-content: center;
    padding : 1rem;
    padding-top : 0;

}

.m-footer {
    display: flex;
    justify-content: space-between;
    flex : 0 0 100%;
}
.modal-backdrop.show{
    
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    opacity: 0.7 !important;
}