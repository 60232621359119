.reports-content {
	text-align: center;
	padding: 0.5rem 0.7rem 0 0.7rem;
}

.reports-content-row {
	background: white;
	box-shadow: 2px 0px 4px 2px rgba(0, 0, 0, 0.1), 2px 4px 4px -2px rgba(0, 0, 0, 0.1);
	/* padding-top: 16px; */
	/* min-height: 200px; */
	max-height: 400px;
	/* padding-right: 27px; */
	border-radius: 20px;
	 overflow-y: auto;
	overflow-x: hidden; 
	margin-bottom: 30px;
	margin: 0px 0px 27px 0px;
}
.reports-content-row::-webkit-scrollbar {
    display: none;
}
/* .connectors-table-data{
	overflow-y: auto;
	overflow-x: hidden;
	min-height: 200px;
	max-height: 500px;
} */
.portfolio-card-pd {
	float: right;
	padding: 10px 30px 10px 30px;
	border-radius: 5px;
	width: 50%;
	margin-bottom: 20px;
}

.reports-content-box {
	padding: 0;
	margin: 0;
}

.porfolio-cards {
	height: 450px;
	box-shadow: 2px 0px 4px 2px rgb(0 0 0 / 10%), 2px 4px 4px -2px rgb(0 0 0 / 10%);
	padding: 15px;
}

.portfolio-card-col-pd {
	padding-top: 20px;
	padding-right: 0px;
}

.porfolio-cards-text {
	margin-top: 22px;
	font-style: normal;
	font-weight: 500;
	float: right;
	font-size: 20px;
	line-height: 10px;
	color: rgba(45, 35, 46, 0.65);
}

.porfolio-cards-value {
	margin-top: 10px;
	font-style: normal;
	font-weight: 700;
	text-align: center;
	font-size: 24px;
	line-height: 44px;
	letter-spacing: 0.0025em;
	color: #2D232E;
}

.xAxisLabel {
	font-size: 20px;
	font-weight: 500;

}

.yAxisLabel {
	font-size: 20px;
	font-weight: 500;
	margin-top: 20px;
}

.reports-content ul {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

.reports-content .reports-li {
	padding-top: 1rem !important;
	list-style: none;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	text-align: center;
}

.table-heading {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #2D232E;
	text-align: left;
}

.month-picker {
	width: 30% !important;
}

.month-picker input {
	padding: 9px 14px !important;
}

.month-picker-mr {
	margin-left: 75px;
	margin-top: 5px;
}

.add-device-section {
	text-align: center;
	background: #FFFFFF;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	padding: 40px;
	margin-bottom: 22px
}

.add-device-section p {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	color: #FF8800;
	cursor: pointer;
}

.portfolio-table-graph .modal-fullscreen {
	width: 80vw;
	margin-left: auto;
}

@media (max-width: 767px) {
	.table-heading {
		text-align: left !important;
		font-size: 12px;
	}

	.reports-content .reports-li {
		text-align: left;
		padding-top: 0rem !important;
	}

	.portfolio-card-col-pd {
		padding-right: 0px !important;
		margin-left: 0px;
		padding-left: 0px;

	}

	.porfolio-cards-text {
		font-size: 12px;
	}

	.porfolio-cards-value {
		font-size: 15px;
	}

	.recharts-cartesian-axis-ticks {
		font-size: 13px;
	}

	.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
		font-size: 13px !important;
		margin-right: 15px;
	}

	.mobile_P {
		padding-left: 0px;
		padding-right: 0px;
		margin-bottom: 15px;
	}

	.reports-content-row {
		padding-right: 0px;
	}

	.reports-content {
		padding: 1rem 0.5rem 0.5rem;
	}

	.dash-border {
		border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
		margin: 31px 20px 24px 7px;
	}

	.reports-li li {
		font-size: 12px;
	}
}

.portfolio-table-graph-wrap {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
}

.portfolio-table-graph-wrapper {
	margin-left: 200px;
}