.left_graph{
    width: 100%;
}
/* .right_graph{
    width: 25%;
} */
.right_graph h3{
    font-weight: 300;
    font-size: 2.3vw;
color: #000000;
}
.right_graph p{
    font-weight: 400;
font-size: 16px;
color: #4D4D4D;
}
.fault_heading{
    font-weight: 400;
font-size: 36px;
color: #333333;
}
.health_card{
    background: #FFFFFF;
    border: 1px solid #EFF0F6;
	box-shadow: 0px 1.56px 9.36px rgba(37, 51, 66, 0.15);
	border-radius: 20px;
	padding: 20px;
	margin-right:20px;
	margin-bottom: 20px;
    height: auto;
}