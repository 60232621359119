.country{
    fill : #ccc;
}

.tool-tip{
    position: absolute;
    padding : 10px 5px 10px 5px;
    background-color: white;
    box-shadow: 0px 1.56px 9.36px rgb(37 51 66 / 15%);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding : 10px 20px 10px 20px;
}

.tool-tip p{
    font-weight: 300;
    margin : 0;
    color : black;
}