.reports-content {
	text-align: center;
	/* padding: 0.5rem 0.7rem 0 0.7rem; */
}
.connectors-table-data>tr:nth-of-type(even) {
   
	background: #F4F6F8;
}
/* .reports-content-row {
	background: white;
	box-shadow: 2px 0px 4px 2px rgba(0, 0, 0, 0.1), 2px 4px 4px -2px rgba(0, 0, 0, 0.1);
	padding-top: 0px !important;
	min-height: 200px;
	max-height: 500px;
	padding-right: 0px !important;
	border-radius: 8px;
	overflow-y: auto;
	overflow-x: hidden;
	margin-bottom: 30px;
} */

.connecter-box {
	background: linear-gradient(90deg, rgba(255, 153, 0, 0.2) 0%, rgba(255, 153, 0, 0) 100%);
	border-radius: 8px;
	/* margin-top: 6rem; */
	padding-top: 15px;
	margin-left: 12px;
}

.counter-box {
	background: #d2cccc;
    border-radius: 8px;
    margin-top: 2rem;
    padding: 10px;
    margin-left: 12px;
    width: 23%;
}
.footer_sec{
	position: fixed;
	width: 100%;
	bottom:0px;
	z-index: 99;
}

.connecter-footer {
	background: #558194;
	width: 100%;
    border-radius: 2px;
	height: 43px;
     margin: 0 auto;
    bottom: 1px;
}


.connecter-footer p {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #FFFFFF;
	padding-top: 13px;
	text-align: center;
}

.connecter-box p {
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 16px;
	letter-spacing: 0.0025em;
	color: #333333;
}
.counter-box h6 {
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 16px;
	letter-spacing: 0.0025em;
	color: #333333;
}

.counter-box p {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	letter-spacing: 0.0025em;
	color: #333333;
	margin-bottom: 0rem;
	margin-top: 3px;
}

.table-header-bg th {
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	text-transform: capitalize;
	line-height: 19px;
	color: #333333;
	padding-top: 15px;
	padding-bottom: 15px;
	vertical-align: middle !important;
}

.portfolio-card-pd {
	float: right;
	padding: 10px 30px 10px 30px;
	border-radius: 5px;
	width: 50%;
	margin-bottom: 20px;
}

.reports-content-box {
	padding: 0;
	margin: 0;
}

table .table-header-bg {
	background: #F4F6F8;
border-radius: 20px 20px 0px 0px;
}

.porfolio-cards {
	height: 450px;
	box-shadow: 2px 0px 4px 2px rgb(0 0 0 / 10%), 2px 4px 4px -2px rgb(0 0 0 / 10%);
	padding: 15px;
}

.portfolio-card-col-pd {
	padding-top: 20px;
	padding-right: 0px;
}

.porfolio-cards-text {
	margin-top: 22px;
	font-style: normal;
	font-weight: 500;
	float: right;
	font-size: 20px;
	line-height: 10px;
	color: rgba(45, 35, 46, 0.65);
}

.porfolio-cards-value {
	margin-top: 10px;
	font-style: normal;
	font-weight: 700;
	text-align: center;
	font-size: 24px;
	line-height: 44px;
	letter-spacing: 0.0025em;
	color: #2D232E;
}

.xAxisLabel {
	font-size: 20px;
	font-weight: 500;

}

.yAxisLabel {
	font-size: 20px;
	font-weight: 500;
	margin-top: 20px;
}

.reports-content ul {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

.reports-content .reports-li {
	padding-top: 1rem !important;
	list-style: none;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	text-align: center;
}

.table-heading {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	text-transform: uppercase;
	color: #333333;
	text-align: center;
}

.connectors-table-data td {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: rgba(45, 35, 46, 0.65);
	padding: 20px;
	vertical-align: middle;
}

.add-device-btn {
	text-align: end;
	margin-bottom: 20px;
}

.add-device-btn button {
	background: #FFFFFF;
	border: 1px solid #FF8800;
	border-radius: 15px;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #FF8800;
	padding: 10px 30px 10px 30px;
}

.month-picker {
	width: 30% !important;
}

.month-picker input {
	padding: 9px 14px !important;
}

.month-picker-mr {
	margin-left: 75px;
	margin-top: 5px;
}

.portfolio-table-graph .modal-fullscreen {
	width: 80vw;
	margin-left: auto;
}

@media (max-width: 767px) {
	.table-heading {
		text-align: left !important;
		font-size: 12px;
	}

	.reports-content .reports-li {
		text-align: left;
		padding-top: 0rem !important;
	}

	.portfolio-card-col-pd {
		padding-right: 0px !important;
		margin-left: 0px;
		padding-left: 0px;

	}

	.porfolio-cards-text {
		font-size: 12px;
	}

	.porfolio-cards-value {
		font-size: 15px;
	}

	.recharts-cartesian-axis-ticks {
		font-size: 13px;
	}

	.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
		font-size: 13px !important;
		margin-right: 15px;
	}

	.mobile_P {
		padding-left: 0px;
		padding-right: 0px;
		margin-bottom: 15px;
	}

	/* .reports-content-row {
		padding-right: 0px;
	} */

	/* .reports-content {
		padding: 1rem 0.5rem 0.5rem;
	} */

	.dash-border {
		border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
		margin: 31px 20px 24px 7px;
	}

	.reports-li li {
		font-size: 12px;
	}
}

.portfolio-table-graph-wrap {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
}

.portfolio-table-graph-wrapper {
	margin-left: 200px;
}
@media (min-resolution: 130dpi) and (max-resolution: 150dpi){
	/* .connecter-footer {
		width: 93.5%;} */
}
.progress_bar{
	display: flex;
	justify-content: center;
}
.progress_bar label{
font-size: 10px;
}
.progress_bar progress{
 height: 18px;
}
.progress_skill {
	background: #F5F5F5;
 border-radius: 20px;
	width: 100%;
	height: 8px;
		
}

.skill1 {
	background-color: #28A5DB;
	padding: 0;
	height: 8px;
	width: 40%;
	text-align: right;
		border-radius: 15px;
}
.skill2 {
	background-color: #6F87DB;
	padding: 0;
	height: 8px;
	width: 40%;
	text-align: right;
		border-radius: 15px;
}