.sankey {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.hoverDiv {
    position: absolute;
    opacity: 0;
    box-shadow: 0px 1.56px 9.36px rgb(37 51 66 / 15%);
    border-radius: 8px;
    background-color: #fff;
    padding : 10px 20px 10px 20px;
}