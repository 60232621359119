/* .status-col {
    padding: 2.75rem 2rem 0 2rem;
} */


/* The container */
.span-radio-input-radio {
    /* display: block; */
    position: relative;
    padding-left: 23px;
    margin-bottom: 12px;
    cursor: pointer;
    /* font-size: 22px; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.span-radio-input-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0px;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid #FF8800;
}

/* On mouse-over, add a grey background color */
/* .span-radio-input-radio:hover input ~ .checkmark {
    background-color: #ccc;
  } */

/* When the radio button is checked, add a blue background */
/* .span-radio-input input:checked ~ .checkmark {
    background-color: #2196F3;
  } */

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.span-radio-input-radio input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.span-radio-input-radio .checkmark:after {
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #FF8800;
}

.status-content {
    background: white;
    padding: 30px 15px 15px 15px;
    height: auto;
    border-radius: 8px;
}

.losses-graph {
    background: white;
    padding: 25px 0px 20px 0px;
    height: auto;
    border-radius: 8px;
    margin: 30px 0px 30px 0px;
}

.card-status-content {
    background: white;
    padding: 2.65rem;
    height: auto;
    border-radius: 8px;
    margin: 20px 0px 30px 0px;
}

.status-heading {
    margin: 0;
    /* padding-bottom: 1.15rem; */
    text-transform: capitalize;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: #2D232E;
}

.radio-div {
    padding-top: 1.45rem;
    padding-bottom: 20px;
}

.span-radio-input {
    margin-right: 1.25rem;
}

.no-data-box {
    background-color: #f0f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    height : 100%;
    font-size: 2rem;
    font-weight: 500;
    color : #0008;
}

.time-series-graph {
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 44px;
    color: #2D232E;
    margin-top: 3rem;
    width: fit-content;
    background: linear-gradient(180deg, #FFF3E0 1.93%, rgb(255 243 224 / 19%) 96.3%);
    border-top: 2px solid #FF8800;
    padding: 10px;
    margin-bottom: 0px !important;
    text-align: center;
}

.status-input-label {
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
    padding: 0 .55rem;
    color: rgba(45, 35, 46, 0.65);
}

/* Date */
.date-div {
     width : 100%;
    position: relative;
}
.calendar_icon{
    position: absolute;
    z-index: 9;
    top: 30%;
    left: 8%;
    width: 17px;
}
.down_icon{
    position: absolute;
    z-index: 9;
    top: 41%;
    right:4%;
    width: 17px;
}
.grid-button{
    display: grid;
    grid-template-columns: 22% 22% 26% 24%;
    gap: 0px 20px;
}
.date-input {
    padding: 10px;
}

.date-div .start-date {
    filter: drop-shadow(0px 1.56px 9.36px rgba(37, 51, 66, 0.15));
    background: #FFFFFF;
    border: 1px solid #EFF0F6;
    border-radius: 4px;
    width: 100%;
    text-align: center;
}

.date-div .end-date {
    border: 1px solid rgba(45, 35, 46, 0.2);
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}

.date-div .end-date::placeholder {
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000000;
}

.date-div .start-date::placeholder {
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000000;
}

/* Choose */
.choose-div {
    padding-top: 1.95rem;
}

.choose-heading {
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
    color: #2D232E;
}

.choose-btn {
    border: 1px solid rgba(45, 35, 46, 0.2);
    outline: none;
    box-sizing: border-box;
    border-radius: 8px;
    background: #fff;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    min-width: 30%;
    height: 55px;
    margin-right: 2.25rem;
    color: #2D232E;
}

.download-btn {
    padding: 0.65rem 3.15rem;
    border: 1px solid #FF8800;
    border-radius: 4px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FF8800;
    font-weight: 500;
    margin-left: 2rem;
    transition: all .3s ease-in;
}

.download-btn_dissabled {
    padding: 0.65rem 3.15rem;
    border: 1px solid #FF8800;
    border-radius: 4px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #e9dccd;
    font-weight: 500;
    margin-left: 2rem;
    transition: all .3s ease-in;
}

.choose-btn:hover,
.choose-btn:active {
    background: rgba(255, 174, 0, 0.2);
    border: 1px solid #FF8800;
}

.enter-email-input {
    width: 52%;
    height: 55px;
    margin-top: 1.95rem;
    padding: 0 0 0 1.25rem;
    font-weight: 400;
    outline: none;
    font-size: 16px;
    border: 1px solid rgba(45, 35, 46, 0.2);
    color: rgba(45, 35, 46, 0.65);
    border-radius: 8px;
}

.choose-get-btn {
    margin-top: 1.95rem;
    color: #fff;
    background: #FF8800;
    border-radius: 4px;
    border: 1px solid #FF8800;
    width: 23%;
    height: 40px;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
}

.choose-get-btn_disable {
    margin-top: 1.95rem;
    color: #fff;
    background: #e4dcd2;
    border-radius: 4px;
    border: 1px solid #FF8800;
    width: 23%;
    height: 40px;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
}

.choose-get-whatsapp-btn {
    margin-top: 1.95rem;
    margin-left: 1rem;
    color: #fff;
    background: #25D366;
    border-radius: 4px;
    border: 1px solid #25D366;
    width: 20%;
    height: 40px;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
}

.choose-get-whatsapp-btn_dissabled {
    margin-top: 1.95rem;
    margin-left: 1rem;
    color: #fff;
    background: #7fb492;
    border-radius: 4px;
    border: 1px solid #25D366;
    width: 20%;
    height: 40px;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
}

.status-pd {
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
}

.status-report {
    margin-left: 10px;
}

.losses-analysis-wrap {
    margin-top: 20px;
}

@media (max-width: 767px) {

    .status-pd {
        width: 100%;
        margin-bottom: 10px;
        margin-left: 0px !important;
    }

    .date-div .start-date {
        border: 1px solid rgba(45, 35, 46, 0.2);
        border-radius: 5px;
        width: 100%;
    }

    .radio-div {
        display: grid;
    }

    .enter-email-input {
        width: 100%;
    }

    .choose-get-btn {
        width: 100%;
    }

    .download-btn {
        margin-left: 0rem;
        transition: all .3s ease-in;
        width: 100%;
        display: block;
    }
}
/* .recharts-text.recharts-label{
    position: absolute !important;
    left: 100px !important;
} */
.interval_class{
    font-weight: 500;
font-size: 16px;
color: #333333;
}
.btn-projection {
    text-align: center;
    position: relative;
    padding: 2% 4%;
    display: flex;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    color:white;
    background-color: #F2A541;
    border-radius: 4px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
}

.btn-predict{
    text-align: center;
    position: relative;
    width: 28%;
    padding: 5px;
    display: flex;
    border-radius: 4px;
    border : 1px solid #899197;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color:#899197;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
}

.btn-fault-category{
    text-align: center;
    width: 100%;
    padding: 5px;
    display: flex;
    border-radius: 4px;
    border : none;
    align-items: center;
    justify-content: center;
    color:white;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
}

.wrapper {
    position: relative;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.speedometer {
    overflow: hidden;
    width: 210px;
    height: 120px;
    position: relative;
    margin: 20px auto;
}
.speedometer::after {
    background-color: #fff;
    height: 10px;
    width: 130px;
    margin: 0 auto;
    left: 0;
    bottom: 0;
    content: "";
    position: absolute;
    border-radius: 1px;
    z-index: 40;
    right: 0;
}
.speedometer .dial {
    padding-top: 20px;
    overflow: hidden;
}
.speedometer .needle {
    position: absolute;
    left: 0;
    right: 0;
    top: 2px;
    width: 12px;
    margin: 0 auto;
    z-index: 50;
}
.speedometer .needle .inner {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMSBUaW55Ly9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLXRpbnkuZHRkIj48c3ZnIHZlcnNpb249IjEuMSIgYmFzZVByb2ZpbGU9InRpbnkiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjEycHgiIGhlaWdodD0iMTEzcHgiIHZpZXdCb3g9IjAgMCAxMiAxMTMiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiM2NzY3NjciIGQ9Ik0wLjA2NywxMDYuMzQ0Yy0wLjAxNiwwLjEzNS0wLjAyNCwwLjU0My0wLjAzMiwwLjY4MWwtMC4wMTIsMC40MTFoMC4wMDVjLTAuMDAxLDAtMC4wMTEtMC4xNDYtMC4wMTEtMC4wODFjMC4wMDYsMy4xOSwyLjY4OSw1LjYzNSw1Ljk5NSw1LjYyOWMzLjMwNC0wLjAwNyw1Ljk3OC0yLjY2Niw1Ljk3MS01Ljg1NmMtMC4wMDEtMC40MTUtMC4wNS0wLjg1My0wLjEzNi0xLjI0Mkw2LjAxMiwwLjEyNEwwLjA2NywxMDYuMzQ0eiIvPjwvc3ZnPg==);
    background-size: 100%;
    height: 112px;
    width: 12px;
    position: relative;
    transform: rotate(90deg);
    transform-origin: bottom center;
}
.speedometer .needle .top,
.speedometer .needle .bottom {
    display: none;
    position: absolute;
}
.speedometer .needle .top {
    border-color: transparent;
}
.speedometer .needle .bottom {
    background-color: transparent;
}
.speedometer .bar {
    position: relative;
    overflow: hidden;
    height: 95px;
}
.speedometer .half-circle {
    height: 95px;
    width: 190px;
    border-radius: 190px 190px 0 0;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transform-origin: bottom center;
}
.speedometer .half-circle-bg {
    background-color: #e0f3fc;
    z-index: 10;
}
.speedometer .half-circle-speed {
    background-color: #0590dd;
    z-index: 20;
}
.speedometer .half-circle-cover {
    background-color: #fff;
    height: 65px;
    width: 130px;
    border-radius: 190px 190px 0 0;
    background: #fff;
    margin: 0 auto;
    top: auto;
    bottom: 0;
    z-index: 30;
}
.speedometer .animated {
    transform: rotate(-180deg);
    transform-origin: bottom center;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}
[class*=sm] .speedometer {
    width: 130.2px;
    height: 74.4px;
    position: relative;
    margin: 20px auto;
}
[class*=sm] .speedometer::after {
    height: 6.2px;
    width: 80.6px;
}
[class*=sm] .speedometer .dial {
    padding-top: 12.4px;
}
[class*=sm] .speedometer .bar {
    position: relative;
    overflow: hidden;
    height: 58.9px;
}
[class*=sm] .speedometer .half-circle {
    height: 58.9px;
    width: 117.8px;
    border-radius: 117.8px 117.8px 0 0;
    transform-origin: bottom center;
}
[class*=sm] .speedometer .half-circle-cover {
    height: 40.3px;
    width: 80.6px;
    border-radius: 117.8px 117.8px 0 0;
}
[class*=sm] .speedometer .needle {
    width: 7.44px;
}
[class*=sm] .speedometer .needle .inner {
    height: 69.44px;
    width: 7.44px;
}
.scaled-90 .speedometer {
    width: 189px;
    height: 108px;
    position: relative;
    margin: 20px auto;
}
.scaled-90 .speedometer::after {
    height: 9px;
    width: 117px;
}
.scaled-90 .speedometer .dial {
    padding-top: 18px;
}
.scaled-90 .speedometer .bar {
    position: relative;
    overflow: hidden;
    height: 85.5px;
}
.scaled-90 .speedometer .half-circle {
    height: 85.5px;
    width: 171px;
    border-radius: 171px 171px 0 0;
    transform-origin: bottom center;
}
.scaled-90 .speedometer .half-circle-cover {
    height: 58.5px;
    width: 117px;
    border-radius: 171px 171px 0 0;
}
.scaled-90 .speedometer .needle {
    width: 10.8px;
}
.scaled-90 .speedometer .needle .inner {
    height: 100.8px;
    width: 10.8px;
}
.scaled-80 .speedometer {
    width: 168px;
    height: 96px;
    position: relative;
    margin: 20px auto;
}
.scaled-80 .speedometer::after {
    height: 8px;
    width: 104px;
}
.scaled-80 .speedometer .dial {
    padding-top: 16px;
}
.scaled-80 .speedometer .bar {
    position: relative;
    overflow: hidden;
    height: 76px;
}
.scaled-80 .speedometer .half-circle {
    height: 76px;
    width: 152px;
    border-radius: 152px 152px 0 0;
    transform-origin: bottom center;
}
.scaled-80 .speedometer .half-circle-cover {
    height: 52px;
    width: 104px;
    border-radius: 152px 152px 0 0;
}
.scaled-80 .speedometer .needle {
    width: 9.6px;
}
.scaled-80 .speedometer .needle .inner {
    height: 89.6px;
    width: 9.6px;
}
.scaled-70 .speedometer {
    width: 147px;
    height: 84px;
    position: relative;
    margin: 20px auto;
}
.scaled-70 .speedometer::after {
    height: 7px;
    width: 91px;
}
.scaled-70 .speedometer .dial {
    padding-top: 14px;
}
.scaled-70 .speedometer .bar {
    position: relative;
    overflow: hidden;
    height: 66.5px;
}
.scaled-70 .speedometer .half-circle {
    height: 66.5px;
    width: 133px;
    border-radius: 133px 133px 0 0;
    transform-origin: bottom center;
}
.scaled-70 .speedometer .half-circle-cover {
    height: 45.5px;
    width: 91px;
    border-radius: 133px 133px 0 0;
}
.scaled-70 .speedometer .needle {
    width: 8.4px;
}
.scaled-70 .speedometer .needle .inner {
    height: 78.4px;
    width: 8.4px;
}
.scaled-60 .speedometer {
    width: 126px;
    height: 72px;
    position: relative;
    margin: 20px auto;
}
.scaled-60 .speedometer::after {
    height: 6px;
    width: 78px;
}
.scaled-60 .speedometer .dial {
    padding-top: 12px;
}
.scaled-60 .speedometer .bar {
    position: relative;
    overflow: hidden;
    height: 57px;
}
.scaled-60 .speedometer .half-circle {
    height: 57px;
    width: 114px;
    border-radius: 114px 114px 0 0;
    transform-origin: bottom center;
}
.scaled-60 .speedometer .half-circle-cover {
    height: 39px;
    width: 78px;
    border-radius: 114px 114px 0 0;
}
.scaled-60 .speedometer .needle {
    width: 7.2px;
}
.scaled-60 .speedometer .needle .inner {
    height: 67.2px;
    width: 7.2px;
}
.scaled-50 .speedometer {
    width: 105px;
    height: 60px;
    position: relative;
    margin: 20px auto;
}
.scaled-50 .speedometer::after {
    height: 5px;
    width: 65px;
}
.scaled-50 .speedometer .dial {
    padding-top: 10px;
}
.scaled-50 .speedometer .bar {
    position: relative;
    overflow: hidden;
    height: 47.5px;
}
.scaled-50 .speedometer .half-circle {
    height: 47.5px;
    width: 95px;
    border-radius: 95px 95px 0 0;
    transform-origin: bottom center;
}
.scaled-50 .speedometer .half-circle-cover {
    height: 32.5px;
    width: 65px;
    border-radius: 95px 95px 0 0;
}
.scaled-50 .speedometer .needle {
    width: 6px;
}
.scaled-50 .speedometer .needle .inner {
    height: 56px;
    width: 6px;
}
.percent-100 .speedometer.play .animated,
.percent-100-sm .speedometer.play .animated {
    -webkit-animation-name: to-100;
    animation-name: to-100;
}

.percent-100-static .animated,
.percent-100-static-sm .animated {
    -webkit-animation: none;
    animation: none;
    transform: rotate(0deg);
}

.percent-87 .speedometer.play .animated,
.percent-87-sm .speedometer.play .animated {
    -webkit-animation-name: to-87;
    animation-name: to-87;
}

.percent-87-static .animated,
.percent-87-static-sm .animated {
    -webkit-animation: none;
    animation: none;
    transform: rotate(-80.12deg);
}

.percent-60 .speedometer.play .animated,
.percent-60-sm .speedometer.play .animated {
    -webkit-animation-name: to-60;
    animation-name: to-60;
}

.percent-60-static .animated,
.percent-60-static-sm .animated {
    -webkit-animation: none;
    animation: none;
    transform: rotate(-72deg);
}

.percent-40 .speedometer.play .animated,
.percent-40-sm .speedometer.play .animated {
    -webkit-animation-name: to-40;
    animation-name: to-40;
}

.percent-40-static .animated,
.percent-40-static-sm .animated {
    -webkit-animation: none;
    animation: none;
    transform: rotate(-108deg);
}

.percent-33 .speedometer.play .animated,
.percent-33-sm .speedometer.play .animated {
    -webkit-animation-name: to-33;
    animation-name: to-33;
}

.percent-33-static .animated,
.percent-33-static-sm .animated {
    -webkit-animation: none;
    animation: none;
    transform: rotate(-120.06deg);
}

.percent-30 .speedometer.play .animated,
.percent-30-sm .speedometer.play .animated {
    -webkit-animation-name: to-30;
    animation-name: to-30;
}

.percent-30-static .animated,
.percent-30-static-sm .animated {
    -webkit-animation: none;
    animation: none;
    transform: rotate(-126deg);
}

.percent-16 .speedometer.play .animated,
.percent-16-sm .speedometer.play .animated {
    -webkit-animation-name: to-16;
    animation-name: to-16;
}

.percent-16-static .animated,
.percent-16-static-sm .animated {
    -webkit-animation: none;
    animation: none;
    transform: rotate(-150.12deg);
}

@-webkit-keyframes to-100 {
    0% {
        transform: rotate(-180deg);
    }
    100% {
        transform: rotate(-60.12deg);
    }
}

@keyframes to-100 {
    0% {
        transform: rotate(-180deg);
    }
    100% {
        transform: rotate(-60.12deg);
    }
}
@-webkit-keyframes to-87 {
    0% {
        transform: rotate(-180deg);
    }
    100% {
        transform: rotate(-60.12deg);
    }
}
@keyframes to-87 {
    0% {
        transform: rotate(-180deg);
    }
    100% {
        transform: rotate(-20.12deg);
    }
}
@-webkit-keyframes to-60 {
    0% {
        transform: rotate(-180deg);
    }
    100% {
        transform: rotate(-72deg);
    }
}
@keyframes to-60 {
    0% {
        transform: rotate(-180deg);
    }
    100% {
        transform: rotate(-72deg);
    }
}
@-webkit-keyframes to-40 {
    0% {
        transform: rotate(-180deg);
    }
    100% {
        transform: rotate(-108deg);
    }
}
@keyframes to-40 {
    0% {
        transform: rotate(-180deg);
    }
    100% {
        transform: rotate(-108deg);
    }
}
@-webkit-keyframes to-33 {
    0% {
        transform: rotate(-180deg);
    }
    100% {
        transform: rotate(-120.06deg);
    }
}
@keyframes to-33 {
    0% {
        transform: rotate(-180deg);
    }
    100% {
        transform: rotate(-120.06deg);
    }
}
@-webkit-keyframes to-30 {
    0% {
        transform: rotate(-180deg);
    }
    100% {
        transform: rotate(-126deg);
    }
}
@keyframes to-30 {
    0% {
        transform: rotate(-180deg);
    }
    100% {
        transform: rotate(-126deg);
    }
}
@-webkit-keyframes to-16 {
    0% {
        transform: rotate(-180deg);
    }
    100% {
        transform: rotate(-150.12deg);
    }
}
@keyframes to-16 {
    0% {
        transform: rotate(-180deg);
    }
    100% {
        transform: rotate(-150.12deg);
    }
}
@media (max-width: 71.875em) {
    .speedometer {
        width: 189px;
        height: 108px;
        position: relative;
        margin: 20px auto;
    }
    .speedometer::after {
        height: 9px;
        width: 117px;
    }
    .speedometer .dial {
        padding-top: 18px;
    }
    .speedometer .bar {
        position: relative;
        overflow: hidden;
        height: 85.5px;
    }
    .speedometer .half-circle {
        height: 85.5px;
        width: 171px;
        border-radius: 171px 171px 0 0;
        transform-origin: bottom center;
    }
    .speedometer .half-circle-cover {
        height: 58.5px;
        width: 117px;
        border-radius: 171px 171px 0 0;
    }
    .speedometer .needle {
        width: 10.8px;
    }
    .speedometer .needle .inner {
        height: 100.8px;
        width: 10.8px;
    }
}
@media (max-width: 64.0625em) {
    .speedometer {
        width: 168px;
        height: 96px;
        position: relative;
        margin: 20px auto;
    }
    .speedometer::after {
        height: 8px;
        width: 104px;
    }
    .speedometer .dial {
        padding-top: 16px;
    }
    .speedometer .bar {
        position: relative;
        overflow: hidden;
        height: 76px;
    }
    .speedometer .half-circle {
        height: 76px;
        width: 152px;
        border-radius: 152px 152px 0 0;
        transform-origin: bottom center;
    }
    .speedometer .half-circle-cover {
        height: 52px;
        width: 104px;
        border-radius: 152px 152px 0 0;
    }
    .speedometer .needle {
        width: 9.6px;
    }
    .speedometer .needle .inner {
        height: 89.6px;
        width: 9.6px;
    }
}
@media (max-width: 56.25em) {
    .speedometer {
        width: 147px;
        height: 84px;
        position: relative;
        margin: 20px auto;
    }
    .speedometer::after {
        height: 7px;
        width: 91px;
    }
    .speedometer .dial {
        padding-top: 14px;
    }
    .speedometer .bar {
        position: relative;
        overflow: hidden;
        height: 66.5px;
    }
    .speedometer .half-circle {
        height: 66.5px;
        width: 133px;
        border-radius: 133px 133px 0 0;
        transform-origin: bottom center;
    }
    .speedometer .half-circle-cover {
        height: 45.5px;
        width: 91px;
        border-radius: 133px 133px 0 0;
    }
    .speedometer .needle {
        width: 8.4px;
    }
    .speedometer .needle .inner {
        height: 78.4px;
        width: 8.4px;
    }
}
@media (max-width: 51.5625em) {
    .speedometer {
        width: 126px;
        height: 72px;
        position: relative;
        margin: 20px auto;
    }
    .speedometer::after {
        height: 6px;
        width: 78px;
    }
    .speedometer .dial {
        padding-top: 12px;
    }
    .speedometer .bar {
        position: relative;
        overflow: hidden;
        height: 57px;
    }
    .speedometer .half-circle {
        height: 57px;
        width: 114px;
        border-radius: 114px 114px 0 0;
        transform-origin: bottom center;
    }
    .speedometer .half-circle-cover {
        height: 39px;
        width: 78px;
        border-radius: 114px 114px 0 0;
    }
    .speedometer .needle {
        width: 7.2px;
    }
    .speedometer .needle .inner {
        height: 67.2px;
        width: 7.2px;
    }
}

.center-position{
    display : flex;
    justify-content : center;
    align-items : center;
    font-weight: 500;
    font-size: 16px;
}

.remove-bottom-margin {
    margin-bottom: 0;
}

/* new-design */
.white_button{
    filter: drop-shadow(0px 1.56px 9.36px rgba(37, 51, 66, 0.15));
    background: #FFFFFF;
    border: 1px solid #EFF0F6;
    border-radius: 4px;
    width: 100%;
    padding : 1%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.white_button h4{
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 0;
}
.refresh_button{
    filter: drop-shadow(0px 1.56px 9.36px rgba(37, 51, 66, 0.15));
    background: #558194;
    border-radius: 4px;
    width :100%;
}
.refresh_button h4{
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    text-align: center;
    padding-top: 10px;
}
.button-margin{
    margin-bottom: 44px;
}
.health_report{
    width: 60%;
    margin: 0 auto;
    text-align: center;
}
.health_report h1{
    font-weight: 600;
    font-size: 32px !important;
    color: #333333;
    display : flex;
    justify-content: center;
}
.health_report h5{
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    margin-bottom: 20px;
}
.email_box{
    background: rgba(85, 129, 148, 0.2);
    border: 1px solid #558194;
    border-radius: 8px;
    width: 50%;
    height: 56px;
    text-align: center;
    margin-right: 32px;
}
.email_box p{
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    padding-top: 15px;
    cursor: pointer;
}
.whatsup_box{
    background: #FFFFFF;
    border: 1px solid rgba(45, 35, 46, 0.2);
    border-radius: 8px;
    width: 50%;
    height: 56px;
    text-align: center;
    cursor: pointer;
}
.whatsup_box p{
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    padding-top: 15px;
}
.whatsup_box i{
    font-size: 21px;
    margin-right: 8px;
    position: relative;
    top: 2px;
}
.health_input{
    background: #FFFFFF;
    border: 1px solid rgba(45, 35, 46, 0.2);
    border-radius: 8px;
    width: 100%;
    height: 54px;
    margin-top: 10px;
    padding: 0px 20px;
}
.gray_but{
    background: #558194;
    border-radius: 4px;
    width: 274px;
    height: 54px;
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.04em;
    border:0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.gray_but img {
    margin-right: 7px;
}

@media (min-width: 1025px) and (max-width:1300px) {
    .date-div .start-date{
        font-size: 1vw;
    }
    .calendar_icon{
        width: 12px;
    }
    .down_icon{
        width: 12px;
    }
}

.grid-class{
    display: grid;
  gap: 20px 20px;
  grid-template-columns: 73.2% 25%;
}
.right-side-box{
    display: grid;
    gap: 0px 0px;
    grid-template-columns: 50% 50%;
}
.grid-home{
    display: grid;
    gap: 0px 0px;
    grid-template-columns: 50% 50%;
}
.status_modal .modal-content
    {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    height: 50vh;
}

.status_modal .modal-dialog {
    max-width: 70vw !important;
}

.status_modal .modal-title {
    font-size: 18px;
    font-weight: 600;
font-size: 16px;
color: #4D4D4D;
}
.status-box{
    background: #FFFFFF;
    border: 1px solid #EFF0F6;
	box-shadow: 0px 1.56px 9.36px rgba(37, 51, 66, 0.15);
	border-radius: 20px;
	padding: 20px;
	/* margin-right:20px; */
	margin-bottom: 20px;
    height: 420px;
}
.connector_data{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.flex-item-right{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 112px;
    background-color: #fff;
    filter: drop-shadow(0px 1.56px 9.36px rgba(37, 51, 66, 0.15));
    border-radius: 20px;
}
.connector_data h5{
    color: #0057FF;
    font-weight: 500;
font-size: 14px;
text-align: center;
line-height: 26px;
margin-bottom: 0rem;
padding: 0px 20px 0px 0px;
}
.connector_data p{
    font-weight: 500;
    font-size: 14px;
    padding: 3px 15px;
    margin-bottom: 0px;
}
@media (min-width: 1200px) and (max-width:1300px) {
    .connector_data h5{
        font-size: 12px; 
    }
    .connector_data p{
        font-size: 12px; 
    }
}